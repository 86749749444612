<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		ref="autoshipModal"
		no-close-on-esc
		no-close-on-backdrop
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		scrollable>
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ title }}
			</h5>
		</template>
		<template #modal-footer>
			<div class="row w-100">
				<div class="col-12 w-100 d-flex justify-content-end">
					<div
						v-for="(item, index) in discounts"
						:key="index"
						:style="{
							width: `${item.width}%`,
							fontSize: currentProgress >= item.min ? '20px' : ''
						}"
						:class="{
							'text-muted': currentProgress < item.min,
							'text-discount': currentProgress >= item.min,
						}"
						class="p-0 d-flex flex-column align-items-end font-weight-bold"
						style="align-self: flex-end;">
						${{ item.discount }}
						<i
							class="fas fa-caret-up fa-lg text-lime" />
					</div>
				</div>
				<div
					v-if="fullyCustomizable"
					class="col-12">
					<b-progress
						:max="packVolume"
						precision="0.01"
						:animated="true"
						:variant="progressVariant"
						height="30px"
						striped
						class="mt-auto">
						<b-progress-bar
							:value="currentVolumeProgress"
							:class="progressClass">
							<strong style="width: 100%; position: absolute; font-size: 1.7em;">
								<span class="text-dark bg-light h-100 rounded px-3">
									{{ currentVolumeProgress + ' / ' + packVolume + ' ' + translate('bv') }}
								</span>
							</strong>
						</b-progress-bar>
					</b-progress>
					<h6
						v-if="upgradeText"
						class="text-discount text-center mt-1">
						{{ upgradeText }}
					</h6>
				</div>
				<div class="col-12 text-right mt-1">
					<!--<h6>{{ translate('subtotal') }} {{ currentProgress | currency('usd', translate) }}</h6>-->
					<h3>{{ translate('total') }} {{ currentProgress - discount | currency('usd', translate) }}</h3>
				</div>
				<div class="col col-12 no-gutters justify-content-end text-right">
					<button
						type="button"
						:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
						aria-label="Close"
						class="btn w-100 btn-secondary btn-lg mt-3 mr-2"
						@click="cancel()">
						{{ translate('cancel') }}
					</button>
					<button
						:disabled="disableConfirm || isCalculationLoading"
						type="button"
						:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
						aria-label="Close"
						class="btn w-100 btn-primary btn-lg mt-3"
						@click="confirm()">
						{{ translate('confirm_autoship') }}
					</button>
				</div>
			</div>
		</template>
		<div
			v-if="!loading"
			class="row no-gutters justify-content-center">
			<div
				v-if="!!description"
				class="col-12">
				<div class="my-2">
					<b-alert
						variant="info"
						class="m-0"
						show
						v-html="description" />
				</div>
			</div>
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
				class="col-12">
				<div class="row mx-2 mt-1">
					<div class="col-12">
						<div
							v-for="(item, key) in configurableProducts"
							:key="item.attributes.sku"
							:class="key < configurableProducts.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 mb-2' : ''"
							class="row">
							<div class="col">
								<configurable-product
									:code-name="item.attributes.code_name"
									:sku="item.attributes.sku"
									:config-name="getProductConfigKey(item)"
									:thumbnail="item.attributes.thumbnail"
									:stacked-layout="['xs', 'sm'].includes(windowWidth)">
									<div class="row">
										<div
											v-for="(config, configKey) in getProductConfigs(item)"
											:key="configKey"
											class="col-12 col-md-6">
											<product-configuration
												:configuration="getConfiguredProduct(item, configKey).code_name"
												:init-qty="selectedProducts[getConfiguredProduct(item, configKey).sku].value || 0"
												:stacked-layout="['xs', 'sm'].includes(windowWidth)"
												@change="$set(selectedProducts[getConfiguredProduct(item, configKey).sku], 'value', $event)" />
										</div>
									</div>
								</configurable-product>
							</div>
						</div>
					</div>
					<div class="col-12 border border-secondary border-bottom-0 border-left-0 border-right-0 pt-2 mt-1">
						<div class="row mb-3">
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'pl-0' : ''"
								class="col-12">
								<div class="row">
									<div
										v-for="item in simpleProducts"
										:key="item.attributes.sku"
										class="col-12 col-md-6">
										<simple-product
											:code-name="item.attributes.code_name"
											:bvs="Number(item.attributes.bvs)"
											:init-qty="selectedProducts[item.attributes.sku].value || 0"
											:thumbnail="item.attributes.thumbnail"
											:stacked-layout="['xs', 'sm'].includes(windowWidth)"
											@change="$set(selectedProducts[item.attributes.sku], 'value', $event)" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="!!products.length"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</b-modal>
</template>
<script>
import IsLoading from '@/components/Loading';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { Products, Purchase } from '@/translations';
import ConfigurableProduct from './ConfigurableProduct';
import ProductConfiguration from './ProductConfiguration';
import SimpleProduct from './SimpleProduct';
import { AUTOSHIP_MIN_QTY } from '@/settings/Wizard';
import { currency } from '@/config/Filters';
import GeneralInformation from '@/util/GeneralInformation';

export default {
	name: 'AdditionalProductsModal',
	messages: [Products, Purchase],
	components: {
		ConfigurableProduct,
		IsLoading,
		ProductConfiguration,
		SimpleProduct,
	},
	filters: {
		currency,
	},
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		autoshipProducts: {
			type: Object,
			default: () => ({}),
		},
		loadingProducts: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		products: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		discounts: {
			type: Array,
			default: () => [],
		},
		packPrice: {
			type: Number,
			default: 0,
		},
		packVolume: {
			type: Number,
			default: 0,
		},
		fullyCustomizable: {
			type: Boolean,
			default: false,
		},
		selectedPack: {
			type: String,
			default: '',
		},
		isRegister: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedProducts: {},
			packageCalculation: new GeneralInformation(),
		};
	},
	computed: {
		currentProgress() {
			const amount = this.packPrice;
			let extraProductsAmount = 0;
			const productsWithDiscount = [];
			let productWithTenDiscount = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productPrice = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productPrice = product.attributes.price_amount;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productPrice = product.attributes.children[sku].price_amount;
						}
					});
				});

				if (productPrice > 0 && productsWithDiscount.some((prefix) => key.startsWith(prefix))) {
					productWithTenDiscount += value.value;
				}
				extraProductsAmount += value.value * productPrice;
			});
			extraProductsAmount -= 10 * productWithTenDiscount;
			return amount + extraProductsAmount;
		},
		currentVolumeProgress() {
			let productsVolume = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productVolume = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productVolume = product.attributes.bvs;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productVolume = product.attributes.children[sku].bvs;
						}
					});
				});

				productsVolume += value.value * productVolume;
			});
			return productsVolume;
		},
		progressVariant() {
			return this.currentVolumeProgress >= this.packVolume ? 'secondary' : 'primary';
		},
		progressClass() {
			return this.currentVolumeProgress >= this.packVolume ? 'success-background' : '';
		},
		maxDiscount() {
			try {
				if (this.discounts.length > 0) {
					return this.discounts[this.discounts.length - 1].min;
				}
			} catch (error) {
				return 0;
			}

			return 0;
		},
		discount() {
			return [...this.discounts].reverse().find((item) => this.currentProgress >= item.min)?.discount ?? 0;
		},
		loading() {
			return this.loadingProducts || !Object.keys(this.selectedProducts).length || !this.products.length;
		},
		simpleProducts() {
			return this.products.filter((product) => !product.attributes.has_configurations);
		},
		configurableProducts() {
			return this.products.filter((product) => product.attributes.has_configurations);
		},
		disableConfirm() {
			if (this.fullyCustomizable) {
				return this.currentVolumeProgress < this.packVolume;
			}
			const selectedQty = Object.values(this.selectedProducts).reduce((accum, product) => accum + product.value, 0);
			const minQty = AUTOSHIP_MIN_QTY[this.country];
			return selectedQty < minQty;
		},
		upgradeText() {
			try {
				return this.packageCalculation.data.response.data.response.product.upgraded_message;
			} catch (error) {
				return null;
			}
		},
		isCalculationLoading() {
			return !!this.packageCalculation.data.loading;
		},
	},
	watch: {
		open: {
			handler(newVal) {
				if (this.$refs.autoshipModal) {
					if (newVal) {
						this.$refs.autoshipModal.show();
					} else {
						this.$refs.autoshipModal.hide();
					}
				}
			},
			immediate: true,
		},
		autoshipProducts: {
			handler() {
				this.selectedProducts = window.structuredClone(this.autoshipProducts);
			},
			deep: true,
			immediate: true,
		},
		selectedProducts: {
			handler(newVal) {
				const selectedQty = Object.values(newVal).reduce((accum, product) => accum + product.value, 0);
				if (this.fullyCustomizable && selectedQty) {
					const extraProducts = Object.entries(this.selectedProducts).reduce((accumulator, [key, value]) => {
						if (value.value > 0) {
							accumulator[key] = value.value;
						}
						return accumulator;
					}, {});
					this.packageCalculation.packsCalculation({
						flow: this.isRegister ? 'register' : 'purchase',
						country: this.getRegisterCountry(),
						package_code_name: this.selectedPack,
						products: extraProducts,
					});
				}
			},
			deep: true,
		},
	},
	methods: {
		confirm() {
			const extraProducts = Object.entries(this.selectedProducts).reduce((accumulator, [key, value]) => {
				if (value.value > 0) {
					accumulator[key] = value.value;
				}
				return accumulator;
			}, {});
			// eslint-disable-next-line camelcase
			this.$emit('confirm', [extraProducts, this.packageCalculation?.data?.response?.data?.response?.product?.package_code_name]);
		},
		cancel() {
			this.$emit('cancel');
		},
	},
};
</script>
<style>
	/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
	.modal-lg-custom {
		max-width: 880px !important;
		width: 880px !important;
	}
	.modal-footer {
		justify-content: center !important;
	}
	.success-background {
		background-color: #7ebc5a !important;
	}
</style>
